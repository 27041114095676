<script>
  /**
   * This component is intended to either contain both aID-header _and_ site-header
   * or be a parent component to select between them.
   *
   * Thinking that we should expose the general site config though a svelte store
   * instead of passing in the data here.
   */
  import allerBrandLogo from "@/images/aller-brand-logo.svg?raw";
  import InlineSVG from "@/components/InlineSVG.svelte";

  export let siteDomain;
  export let siteFullName;
  export let usePositiveHeader;
  export let legalEntity = 'aid';

  const logoSuffix = usePositiveHeader ? '-positive' : '';
</script>

<header class="{usePositiveHeader ? 'publication__positive' : 'publication__negative'}">
  <a class="lp_logo" href="//{siteDomain}/" aria-label="Gå til forsiden for {siteFullName}">
    <img src="//r.api.no/local/v3/publications/{siteDomain}/gfx/small{logoSuffix}.svg" alt="{siteFullName}">
  </a>
</header>
{#if legalEntity === 'aller'}
  <div class="brand-space">
    <InlineSVG svg={allerBrandLogo}  />
  </div>
{/if}

<style>
  @import "../common/colors.css";

    header {
        display: flex;
        justify-content: center;
        padding: 1rem 2rem;
    }

    header.publication__negative {
        color: var(--publication-color-inverted);
        background-color: var(--header-background-color);
    }

    header.publication__positive {
        color: var(--publication-color);
        border-bottom: 5px solid var(--header-border-color);
        background-color: var(--white);
    }

    a {
        display: block;
        max-width: 600px;
    }

    img {
        height: max(3rem, min(10vw , 5rem));
        width: 100%;
        pointer-events: none;
    }

    .brand-space {
        display: flex;
        justify-content: center;
        height: 3rem;
    }
</style>